import { Main } from "components"
import HeroPortfolio from "components/pages/portfolio/HeroPortfolio"
import PortfolioProjectCard from "components/pages/portfolio/PortfolioProjectCard"
import React from "react"
const Portfolio = () => {
	return (
		<Main>
			<HeroPortfolio />
			<PortfolioProjectCard />
		</Main>
	)
}

export default Portfolio
