import React from "react"
import * as styles from "./styles.module.scss"
import { HeroHeading } from "components"

const HeroPortfolio = () => {
	return (
		<div className={styles.heroPortfolio}>
			<HeroHeading style={{ margin: 0 }}>Our portfolio</HeroHeading>
		</div>
	)
}

export default HeroPortfolio
