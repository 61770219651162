import React from "react"
import { Image } from "react-bootstrap"
import Slider from "react-slick"
import "./styles.module.scss"

const PortfolioCarousel = ({ data }) => {
	console.log(data)
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3500,
		swipeToSlide: true,
		dots: false,
		arrows: false,
	}
	return (
		<Slider {...settings}>
			{data.map((data, index) => (
				<Image src={data.image} fluid />
			))}
		</Slider>
	)
}

export default PortfolioCarousel
