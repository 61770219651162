import React from "react"
import { Button, SectionHeading, Subheading } from "components"
import * as styles from "./styles.module.scss"
import { Card, Col, Container, Image, Row } from "react-bootstrap"
import { content } from "content/portfolio"
import PortfolioCarousel from "../PortfolioCarousel/ProtfolioCarousel"
import ProjectLink from "assets/icons/ProjectLink"

const PortfolioProjectCard = () => {
	return (
		<Container className={styles.container}>
			<Row>
				<Col>
					{content.map((data) => (
						<Card className={styles.card}>
							<Row className="align-items-center  d-flex justify-content-between">
								<Col md={{ span: 6, order: data.key % 2 === 0 ? 2 : 1 }} className="mt-lg-0 mt-md-4 mt-0 mb-auto">
									<PortfolioCarousel data={data.carouseldata} />
								</Col>
								<Col lg={5} md={{ span: 6, order: data.key % 2 === 0 ? 1 : 2 }} className="mb-md-0 mb-3">
									<div data-aos="fade-up" data-aos-duration="2000">
										<Subheading title={data.carddata.title} />
										<p className={styles.text}>{data.carddata.peragraph}</p>
										<div className="  d-flex flex-wrap">
											{data.carddata.techicons.map((data) => (
												<Image className={styles.techlogo} src={data.techlogo} title={data.name} />
											))}
										</div>

										<div className=" mt-md-5 mt-4 mb-md-0 mb-2 d-flex flex-wrap ">
											<Button to={data.carddata.livelink} primary>
												View project
												<ProjectLink />
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						</Card>
					))}
				</Col>
			</Row>
		</Container>
	)
}
export default PortfolioProjectCard
