import React from "react"
import ReactIcon from "assets/icons/ReactIcon.svg"
import fuel_media from "assets/images/fuel_media.png"
import fuel_media_2 from "assets/images/fuel_media_2.png"
import fuel_media_3 from "assets/images/fuel_media_3.png"
import med_opinion from "assets/images/med_opinion.png"
import med_opinion_2 from "assets/images/med_opinion_2.png"
import med_opinion_3 from "assets/images/med_opinion_3.png"
import way_finder from "assets/images/wayfinder.png"
import way_finder_2 from "assets/images/way_finder_2.png"
import way_finder_3 from "assets/images/way_finder_3.png"
import galea_health from "assets/images/galea_health.png"
import galea_health_2 from "assets/images/galea_health_2.png"
import galea_health_3 from "assets/images/galea_health_3.png"
import velocity from "assets/images/velocity.png"
import velocity_2 from "assets/images/velocity_2.png"
import velocity_3 from "assets/images/velocity_3.png"
import box_actaurialr from "assets/images/box_actaurialr.png"
import box_actaurialr_2 from "assets/images/box_actaurial_2.png"
import box_actaurialr_3 from "assets/images/box_actaurial_3.png"
import turp_1 from "assets/images/turp_1.png"
import turp_2 from "assets/images/turp_2.png"
import turp_3 from "assets/images/turp_3.png"
import karri_1 from "assets/images/karri_1.png"
import karri_2 from "assets/images/karri_2.png"
import karri_3 from "assets/images/karri_3.png"
import days_1 from "assets/images/days_1.png"
import days_2 from "assets/images/days_2.png"
import days_3 from "assets/images/days_3.png"
import NodeIcon from "assets/icons/nodeicon.svg"
import python from "assets/icons/python.svg"
import styled_component from "assets/icons/styled_component.svg"
import ant_design from "assets/icons/ant_design.svg"
import mongoDb from "assets/icons/mongoDb.svg"
import fastapi from "assets/icons/fastapi.svg"
import redshift from "assets/icons/redShift.svg"
import aws from "assets/icons/aws.svg"
import flutter from "assets/icons/flutter.svg"
import scss from "assets/icons/scss.svg"
import angular from "assets/icons/angular.svg"

export const content = [
	{
		key: 1,
		carouseldata: [{ image: fuel_media }, { image: fuel_media_2 }, { image: fuel_media_3 }],
		carddata: {
			title: "FuelMedia",
			peragraph:
				" FuelMedia leverages next-generation technology to connect with audiences at an optimal touchpoint during their day by delivering the message to their fuelling customers at the moment of maximum impact. ",
			livelink: "https://fuelmediatv.com/",
			techicons: [
				{ techlogo: ReactIcon, name: "React" },
				{ techlogo: ant_design, name: "Ant Design" },
				{ techlogo: python, name: "python" },
				{ techlogo: fastapi, name: "Fastapi" },
				{ techlogo: mongoDb, name: "Mongo DB" },
			],
		},
	},
	{
		key: 2,
		carouseldata: [{ image: way_finder }, { image: way_finder_2 }, { image: way_finder_3 }],
		carddata: {
			title: "Wayfinder",
			peragraph:
				" A decision making system for Astellas Pharma which provides well established data driven, criteria oriented solutions to help optimise and evaluate their investment decisions. ",
			livelink: "https://www.astellas.com/en/",
			techicons: [
				{ techlogo: ReactIcon, name: "React" },
				{ techlogo: ant_design, name: "Ant Design" },
				{ techlogo: styled_component, name: "Styled Component" },
				{ techlogo: NodeIcon, name: "Node Js" },
				{ techlogo: redshift, name: "AmazonRedshift" },
			],
		},
	},

	{
		key: 3,
		carouseldata: [{ image: galea_health }, { image: galea_health_2 }, { image: galea_health_3 }],
		carddata: {
			title: "Galea Health",
			peragraph:
				" Galea connects athletes and organisations with mental health and performance support ranging from personalised educational resources to athlete-aligned professionals. ",
			livelink: "http://galeahealth.com",
			techicons: [
				{ techlogo: ReactIcon, name: "React" },
				{ techlogo: ant_design, name: "Ant Design" },
				{ techlogo: styled_component, name: "Styled Component" },
				{ techlogo: NodeIcon, name: "Node Js" },
			],
		},
	},
	{
		key: 4,
		carouseldata: [{ image: velocity }, { image: velocity_2 }, { image: velocity_3 }],
		carddata: {
			title: "Velocity",
			peragraph:
				" Velocity is Sentier Analytics’ first of its kind Analytic Operations as a Service (AOaaS) purpose built for commercial pharma which delivers frequent and accurate analytic insights. ",
			livelink: "http://sentieranalytics.com",
			techicons: [
				{ techlogo: ReactIcon, name: "React" },
				{ techlogo: ant_design, name: "Ant Design" },
				{ techlogo: styled_component, name: "Styled Component" },
				{ techlogo: NodeIcon, name: "Node Js" },
			],
		},
	},
	{
		key: 5,
		carouseldata: [{ image: med_opinion }, { image: med_opinion_2 }, { image: med_opinion_3 }],
		carddata: {
			title: "Med Opinion",
			peragraph:
				" Med-opinion helps you diagnose better by collecting your medical history, injury type including all the relevant information and suggesting high qualified doctors available on the platform. ",
			livelink: "http://med-opinion.com",
			techicons: [
				{ techlogo: ReactIcon, name: "React" },
				{ techlogo: ant_design, name: "Ant Design" },
				{ techlogo: styled_component, name: "Styled Component" },
				{ techlogo: NodeIcon, name: "Node Js" },
			],
		},
	},

	{
		key: 6,
		carouseldata: [{ image: box_actaurialr }, { image: box_actaurialr_2 }, { image: box_actaurialr_3 }],
		carddata: {
			title: "Box Actuarial",
			peragraph:
				" Box Actuarial helps their General Insurance clients to improve their pricing processes and enabling their move away from pricing that was fit for last century to pricing techniques that will keep them profitable in the coming decades. ",
			livelink: "http://boxactuarial.com",
			techicons: [
				{ techlogo: ReactIcon, name: "React" },
				{ techlogo: ant_design, name: "Ant Design" },
				{ techlogo: styled_component, name: "Styled Component" },
				{ techlogo: NodeIcon, name: "Node Js" },
			],
		},
	},
	{
		key: 7,
		carouseldata: [{ image: karri_1 }, { image: karri_2 }, { image: karri_3 }],
		carddata: {
			title: "Karri",
			peragraph:
				" A mobile application for parents to make sure that their children are kept in check in terms of their mobility. ",
			livelink: "https://karri.io/",
			techicons: [
				{ techlogo: flutter, name: "Flutter" },
				{ techlogo: NodeIcon, name: "Node Js" },
				{ techlogo: mongoDb, name: "Mongo DB" },
			],
		},
	},
	{
		key: 8,
		carouseldata: [{ image: days_1 }, { image: days_2 }, { image: days_3 }],
		carddata: {
			title: "DAYS (Data At Your Service)",
			peragraph:
				" A sophisticated data wrangling platform possesses the ability to efficiently ingest and process raw data, thus enhancing data quality and usability. With its robust features and intuitive interface, this platform empowers users to seamlessly navigate the intricacies of data manipulation. ",
			livelink: "https://www.behance.net/gallery/130982889/DAYS-DASHBOARD",
			techicons: [
				{ techlogo: ReactIcon, name: "React" },
				{ techlogo: scss, name: "Sass(scss)" },
				{ techlogo: python, name: "python" },
				{ techlogo: aws, name: "AWS" },
			],
		},
	},
	{
		key: 9,
		carouseldata: [{ image: turp_1 }, { image: turp_2 }, { image: turp_3 }],
		carddata: {
			title: "Turp",
			peragraph:
				" A gym and wellness platform based in Turkey. Turp is a two-sided marketplace connecting gyms and spa services to individuals allowing them to keep track of their gym classes and fitness journey. ",
			livelink: "https://www.behance.net/gallery/130897093/Turp-%28A-Gym-management-system%29",
			techicons: [
				{ techlogo: angular, name: "Angular Js" },
				{ techlogo: scss, name: "Sass(scss)" },
				{ techlogo: NodeIcon, name: "Node Js" },
				{ techlogo: mongoDb, name: "Mongo DB" },
			],
		},
	},
]
